import * as React from 'react';

import { Client, Mandate, Portfolio, PortfolioDocument } from 'javascript/models';

import ISelectOption from 'components/interfaces/ISelectOption';

import Presenter from './form/Presenter';

import Context from './form/Context';

interface IProps {
  action: 'create' | 'update';
  adminSystemOptions: ISelectOption[];
  canCreateEntities: boolean;
  canCreateManagers: boolean;
  canCreateClientUsers: boolean;
  clientTypeOptions: ISelectOption[];
  companyId: string;
  contactTypeOptions: ISelectOption[];
  currencyOptions: ISelectOption[];
  dataChaseTypeOptions: ISelectOption[];
  entityFormOptions: any;
  feeType: string;
  feeTypeOptions: any;
  managerFormOptions: { parentManagerOptions: ISelectOption[] };
  mandateDocTypeOptions: ISelectOption[];
  portfolioId: string;
  portfolioStatusOptions: ISelectOption[];
  relationshipManagerOptions: ISelectOption[];
  reviewCommentaryTypeOptions: ISelectOption[];
  reviewFrequencyOptions: ISelectOption[];
  riskOptions: ISelectOption[];
  serviceTypeOptions: ISelectOption[];
  tab: string;
  latestLetterOfEngagement: PortfolioDocument | null;
  latestLetterOfSuitability: PortfolioDocument | null;
  latestInvestmentObjectiveEvaluation: PortfolioDocument | null;
}

export default function portfolio(props: IProps) {
  const { companyId, portfolioId, tab } = props;

  const defaultTabs = [
    { name: 'Information', complete: false },
    { name: 'Service', complete: false },
    { name: 'Mandate', complete: false },
    { name: 'Investment Restrictions', complete: false },
    { name: 'Benchmarks', complete: false },
    { name: 'Documents', complete: false },
    { name: 'Custom', complete: false },
  ];

  const [client, setClient] = React.useState();
  const [clients, setClients] = React.useState([]);
  const [tabs, setTabs] = React.useState(defaultTabs);
  const [portfolio, setPortfolio] = React.useState();
  const [selectedTab, setSelectedTab] = React.useState(tab);
  const [selectedEntity, setSelectedEntity] = React.useState(null);
  const [selectedManager, setSelectedManager] = React.useState(null);
  const [selectedManagerUser, setSelectedManagerUser] = React.useState(null);

  React.useEffect(() => {
    getClients();
  }, []);

  const newMandate = new Mandate({
    hedgeFundMaximum: 100,
    hedgeFundMinimum: 0,
    hedgeFundStrategic: 0,
    cashMaximum: 100,
    cashMinimum: 0,
    cashStrategic: 0,
    equityMaximum: 100,
    equityMinimum: 0,
    equityStrategic: 0,
    fixedIncomeMaximum: 100,
    fixedIncomeMinimum: 0,
    fixedIncomeStrategic: 0,
    otherMaximum: 100,
    otherMinimum: 0,
    otherStrategic: 0,
  });

  React.useEffect(() => {
    if (portfolioId) {
      getPortfolio();
    } else {
      const newPortfolio = new Portfolio({
        alertOnUpdate: false,
        managerUsersIds: [],
        portfolioMandate: newMandate,
        slaDays: 10,
        tabs: defaultTabs,
      });
      setPortfolio(newPortfolio);
    }
  }, []);

  React.useEffect(() => {
    if (portfolio) {
      setTabs(portfolio.tabs);
      if (portfolio.clientId) {
        getClient(portfolio.clientId);
      }
    }
  }, [portfolio]);

  async function getPortfolio() {
    const extraAttributes = [
      'additionalAttributesHash',
      'alertOnUpdate',
      'tabs',
      'contactUsers',
      'benchmarkInformation',
      'reportTemplateIds',
      'managerUsersIds',
      'mandatePath',
      'ipsPath',
      'applicationFormPath',
      'riskProfilerPath',
    ];

    const { data } = await Portfolio.selectExtra(extraAttributes)
      .includes(['entity', 'manager', 'managerUser', 'extraSettings', 'childPortfolios', 'relationshipManager'])
      .includes({ client: ['extra_settings_types', 'fees'] })
      .includes({ portfolio_mandate: [{ compliance: ['flags'] }] })
      .select({ child_portfolios: ['id', 'reference'] })
      .find(portfolioId);

    setPortfolio(data);
  }

  async function getClient(clientId) {
    const { data } = await Client.select(['id', 'name'])
      .selectExtra(['child_client_ids'])
      .includes(['users', 'monitoring_relationship_manager', 'advisory_relationship_manager'])
      .order({ 'users.first_name': 'asc' })
      .find(clientId);

    setClient(data);
  }

  async function getClients() {
    const { data } = await Client.order({ name: 'asc' }).where({ company_id: companyId }).all();

    setClients(data);
  }

  function handleTabChange(index: number) {
    const newSelectedTab = tabs[index];
    setSelectedTab(newSelectedTab.name);
  }

  function handleClientSelect(clientId) {
    getClient(clientId);
  }

  function handleEntityCreation(entity) {
    setSelectedEntity({ label: entity.name, value: entity.id });
  }

  function handleManagerCreation(manager) {
    setSelectedManager({ label: manager.name, value: manager.id });
    setSelectedManagerUser({
      label: `${manager.manager_user.first_name} ${manager.manager_user.last_name}`,
      value: manager.manager_user.id,
    });
  }

  const contextValues = {
    actions: { getClient, handleClientSelect, handleEntityCreation, handleManagerCreation, handleTabChange },
    props: { ...props },
    state: { clients, client, portfolio, selectedEntity, selectedManager, selectedManagerUser, selectedTab, tabs },
  };

  return (
    <Context.Provider value={contextValues}>
      <Presenter />
    </Context.Provider>
  );
}
