import * as React from 'react';

import Information from './form/Information';

import { Client } from 'javascript/models';
import ISelectOption from 'components/interfaces/ISelectOption';

interface IProps {
  adminOptions: ISelectOption[];
  client: any;
  currencyOptions: ISelectOption[];
  clientTypes: ISelectOption[];
  jurisdictionOptions: ISelectOption[];
  regionOptions: ISelectOption[];
  rmAdminOptions: ISelectOption[];
  tierCategories: ISelectOption[];
  tierTypes: ISelectOption[];
  percentageFees: any;
  fixedFees: any;
}

export default function form(props: IProps) {
  const {
    adminOptions,
    client,
    currencyOptions,
    clientTypes,
    jurisdictionOptions,
    regionOptions,
    rmAdminOptions,
    tierCategories,
    tierTypes,
    percentageFees,
    fixedFees,
  } = props;

  const clientResource = new Client(client);
  const clientType = clientResource.clientType;

  const [selectedClientType, setSelectedClientType] = React.useState(clientType);
  const [selectedTierCategory, setSelectedTierCategory] = React.useState(clientResource.tierCategory);
  const [selectedTierType, setSelectedTierType] = React.useState(clientResource.tierType);

  return (
    <React.Fragment>
      <Information
        {...{
          adminOptions,
          clientTypes,
          currencyOptions,
          jurisdictionOptions,
          regionOptions,
          rmAdminOptions,
          setSelectedClientType,
          setTierCategory: setSelectedTierCategory,
          setTierType: setSelectedTierType,
          tierCategories,
          tierTypes,
          percentageFees,
          fixedFees,
        }}
        client={clientResource}
        clientType={selectedClientType}
        tierCategory={selectedTierCategory}
        tierType={selectedTierType}
      />
    </React.Fragment>
  );
}
