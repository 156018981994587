import { Attr, BelongsTo, Model } from 'spraypaint';
import { Client } from './Client';

import { ApplicationRecord } from './ApplicationRecord';

@Model()
export class Fee extends ApplicationRecord {
  public static jsonapiType = 'fees';

  @Attr() public bookkeepingFee: number;
  @Attr() public combinedComponentFee: number;
  @Attr() public combinedFee: number;
  @Attr() public consolidatedFeeTier1: number;
  @Attr() public consolidatedFeeTier3: number;
  @Attr() public emapFee: number;
  @Attr() public feeType: string;
  @Attr() public quantOnlyFee: number;

  @BelongsTo() public client: Client;
}
